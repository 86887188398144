import { ConfigType } from 'providers';

export function getAppConfiguration(): ConfigType {
  return {
    googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    googleAnalyticId: process.env.NEXT_PUBLIC_GA_ID,
    googleAdsId: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
    sentryDns: process.env.NEXT_PUBLIC_SENTRY_DNS,
    sentryEnv: process.env.NEXT_PUBLIC_SENTRY_ENV,
    sentryEnabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
    sentryTracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    sentryAuthToken: process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
    auth0LogoutReturnTo: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO,
    mdcApiHost: process.env.NEXT_PUBLIC_MDC_API_HOST_URL,
    mdcFullApiUrl: `${process.env.NEXT_PUBLIC_MDC_API_HOST_URL}/api/v2/`,
    mdcGraphQlUrl: `${process.env.NEXT_PUBLIC_MDC_API_HOST_URL}/graphql`,
    mdcAccountUrl: process.env.NEXT_PUBLIC_MDC_ACCOUNT_URL,
    cmsApiHost: process.env.NEXT_PUBLIC_CMS_API_HOST_URL,
    cmsFullApiUrl: `${process.env.NEXT_PUBLIC_CMS_API_HOST_URL}/api`,
    cmsGraphQlUrl: `${process.env.NEXT_PUBLIC_CMS_API_HOST_URL}/graphql`,
    auth0Domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    auth0Audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    auth0ClientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    appCode: process.env.NEXT_PUBLIC_APP_CODE,
    theboatAppUrl: process.env.NEXT_PUBLIC_THEBOATAPP_URL,
    mdcLandingUrl: process.env.NEXT_PUBLIC_MARINE_DATA_CLOUD_URL,
    zohoSalesIqWidgetCode: process.env.NEXT_PUBLIC_ZOHO_CHAT_KEY,
    zohoRumKey: process.env.NEXT_PUBLIC_ZOHO_RUM_KEY,
    recaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  };
}
